import React, { ChangeEvent, Fragment, useState } from 'react'
import { useLocation } from '@reach/router'

interface FormProps {
  name: string
  referral_source: any
}

export const Form = (props: FormProps) => {
  const { name, referral_source } = props
  const { pathname } = useLocation()
  const isCareersPage = pathname.includes('/careers')

  const [fileName, setFileName] = useState<string>('No file selected.')
  const handleFilenameChange = (e: any) => {
    const name = e.target.files[0].name
    setFileName(name)
  }

  const [referral, setReferral] = useState<string>('default')
  const handleChange = (e: ChangeEvent<HTMLSelectElement | any>) => {
    setReferral(e.target.value)
  }

  return (
    <form
      method="post"
      netlify-honeypot="bot-field"
      data-netlify="true"
      name={name}
			action="/thank-you"
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value={name} />
      <input
        type="text"
        name="fullname"
        className="form-control"
        placeholder="Full name"
      />
      <input
        type="tel"
        name="phone"
        className="form-control"
        placeholder="Phone"
      />
      <input
        type="email"
        name="email"
        className="form-control"
        placeholder="Email"
      />
      {!isCareersPage && (
        <>
          <input
            type="text"
            name="streetaddress"
            className="form-control"
            placeholder="Street Address"
          />
          <div className="name row">
            <div className="col-4 col-6-mob">
              <input
                type="text"
                name="suburb"
                className="form-control"
                placeholder="Suburb"
              />
            </div>
            <div className="col-4 col-6-mob">
              <input
                type="text"
                name="postcode"
                className="form-control"
                placeholder="Postcode"
              />
            </div>
          </div>
        </>
      )}
      <input
        type="hidden"
        name="referralsource"
        className="form-control hidden"
        value={referral}
        onChange={e => handleChange(e)}
      />
      <select
        name="referral-source"
        className="form-control"
        onChange={e => handleChange(e)}
        defaultValue={referral}
      >
        <option disabled value="default">
          How did you find us?
        </option>
        {referral_source &&
          referral_source.map((item: { source: string }, index: number) => (
            <Fragment key={index}>
              <option key={item.source} value={item.source}>
                {item.source}
              </option>
            </Fragment>
          ))}
      </select>
      <textarea
        name="notes"
        className="form-control"
        placeholder={isCareersPage ? 'Your message' : 'Your enquiry'}
      />
      {isCareersPage && (
        <div className="file-input">
          <input
            type="file"
            id="file"
            className="file"
            onChange={e => handleFilenameChange(e)}
          />
          <label htmlFor="file">Upload a file</label>
          <p className="file-name">{fileName}</p>
        </div>
      )}
      <button type="submit" className="btn">
        Submit
      </button>
    </form>
  )
}
