import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Form } from '../components/form'
import BlockText from '../components/block-text'

const Contact = () => {
  const data = useStaticQuery(graphql`
    query {
      sanityContact {
        id
        title
        _rawContactText
        referral_source {
          source
        }
      }
    }
  `)
  const { title, _rawContactText, referral_source } = data.sanityContact

  return (
    <Layout>
      <SEO title={title} />
      <div className="container-theme">
        <div className="contact-page container-fluid">
          <section className="contact-page-top row">
            <div className="contact col-md-4 offset-3 col-sm-5 col-6-mob offset-0-mob">
              <h1 className="contact-title">{title}</h1>
              <BlockText className="contact-text" blocks={_rawContactText} />
            </div>
          </section>
          <section className="contact-page-form row">
            <div className="page-form col-md-4 offset-3 col-sm-5 col-6-mob offset-0-mob">
              <Form name="contact" referral_source={referral_source} />
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
